import React from "react";

class Home extends React.Component {
    render() {
        return (
            <header className="masthead">
              <div className="container">
                <div className="intro-text">
                  <div className="intro-lead-in">Sumbu Filosofis</div>
                    <div className="intro-heading">Philosophical axis</div>
                    <div className="intro-heading1">ꦱꦸꦩ꧀ꦧꦸ​ꦥ꦳ꦶꦭꦺꦴꦱꦺꦴꦥ꦳ꦶꦱ꧀</div>
                    </div>
                  </div>  
            </header>
        )
    }
}

export default Home;